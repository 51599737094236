<template>
  <Page>
    <template v-slot:content>
      <div class="howitworks-page--container">
        <Section id="header" bgColor="#002C5F">
          <div class="header">
            <Label
              class="header--subtitle"
              :styles="{ color: 'white' }"
              align="center"
              size="medium"
              bold="thin"
              >{{ $t("pages.howitworks.sections.header.subtitle") }} <br
            /></Label>
            <Heading
              class="header--title"
              :label="$t('pages.howitworks.sections.header.title')"
              tag="h1"
              weight="bold"
            />
          </div>
        </Section>
        <Section id="sub-header" class="sub-header" bgColor="white">
          <div class="header">
            <Heading
              class="sub-header-title"
              :label="$t('pages.howitworks.sections.subHeaders[0].title')"
              tag="h2"
              weight="bold"
            />
            <Label
              class="sub-header-subtitle"
              color="tertiary"
              align="center"
              size="small"
              bold="thin"
              >{{ $t("pages.howitworks.sections.subHeaders[0].subtitle") }}
            </Label>
          </div>
        </Section>
        <Section class="content-row" id="content-first-row" bgColor="white">
          <div class="media-content">
            <img
              :src="
                $t('resources.images.howitworkspage.section1') ||
                  require('./../../../assets/images/howitworks-page/find-vehicle.webp')
              "
            />
          </div>
          <div class="text-content">
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.firstRow.labels[0].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t("pages.howitworks.sections.firstRow.labels[0].description")
                }}
              </Label>
            </div>
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.firstRow.labels[1].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t("pages.howitworks.sections.firstRow.labels[1].description")
                }}
              </Label>
            </div>
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.firstRow.labels[2].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t("pages.howitworks.sections.firstRow.labels[2].description")
                }}
              </Label>
            </div>
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.firstRow.labels[3].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t("pages.howitworks.sections.firstRow.labels[3].description")
                }}
              </Label>
              <Link
                class="text-content-link"
                :styles="{ 'justify-content': 'left' }"
                underline
                color="secondary"
                size="small"
                :label="$t('pages.howitworks.sections.firstRow.labels[3].link')"
                href="#"
                @onClick="openRetakePage"
              />
            </div>
          </div>
        </Section>
        <Button
          @onClick="onClickSearch"
          class="row-button"
          fill
          size="medium"
          uppercase
          :label="$t('pages.howitworks.buttons[0].label')"
        ></Button>

        <Section id="sub-header" class="sub-header" bgColor="white">
          <div class="header">
            <Heading
              class="sub-header-title"
              :label="$t('pages.howitworks.sections.subHeaders[1].title')"
              tag="h2"
              weight="bold"
            />
            <Label
              class="sub-header-subtitle"
              color="tertiary"
              align="center"
              size="small"
              bold="thin"
              >{{ $t("pages.howitworks.sections.subHeaders[1].subtitle") }}
            </Label>
          </div>
        </Section>
        <Section class="content-row" id="content-first-row" bgColor="white">
          <div class="text-content">
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.secondRow.labels[0].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t(
                    "pages.howitworks.sections.secondRow.labels[0].description"
                  )
                }}
              </Label>
            </div>
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.secondRow.labels[1].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t(
                    "pages.howitworks.sections.secondRow.labels[1].description"
                  )
                }}
              </Label>
            </div>
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.secondRow.labels[2].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t(
                    "pages.howitworks.sections.secondRow.labels[2].description"
                  )
                }}
              </Label>
            </div>
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.secondRow.labels[3].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t(
                    "pages.howitworks.sections.secondRow.labels[3].description"
                  )
                }}
              </Label>
            </div>
          </div>
          <div class="media-content">
            <img
              :src="
                $t('resources.images.howitworkspage.section2') ||
                  require('./../../../assets/images/howitworks-page/lifestyle.webp')
              "
            />
          </div>
        </Section>

        <Section id="sub-header" class="sub-header" bgColor="#E4ECF0">
          <div class="header">
            <Label
              class="sub-header-title"
              color="primary"
              align="center"
              size="medium"
              bold="bold"
              >{{ $t("pages.howitworks.sections.subHeaders[2].title") }}<br
            /></Label>
            <Button
              @onClick="onClickSearch"
              class="row-button"
              fill
              size="medium"
              uppercase
              :label="
                $t('pages.howitworks.sections.subHeaders[2].buttons[0].label')
              "
            ></Button>
          </div>
        </Section>

        <Section id="sub-header" class="sub-header" bgColor="white">
          <div class="header">
            <Heading
              class="sub-header-title"
              :label="$t('pages.howitworks.sections.subHeaders[3].title')"
              tag="h2"
              weight="bold"
            />
            <Label
              class="sub-header-subtitle"
              color="tertiary"
              align="center"
              size="small"
              bold="thin"
              >{{ $t("pages.howitworks.sections.subHeaders[3].subtitle") }}
            </Label>
          </div>
        </Section>
        <Section class="content-row" id="content-first-row" bgColor="white">
          <div class="media-content">
            <img
              :src="
                $t('resources.images.howitworkspage.section1') ||
                  require('./../../../assets/images/howitworks-page/santa-fe.webp')
              "
            />
          </div>
          <div class="text-content">
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.thirdRow.labels[0].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t("pages.howitworks.sections.thirdRow.labels[0].description")
                }}
              </Label>
            </div>
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.thirdRow.labels[1].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t("pages.howitworks.sections.thirdRow.labels[1].description")
                }}
              </Label>
            </div>
            <div class="content">
              <Heading
                class="text-content-title"
                :label="
                  $t('pages.howitworks.sections.thirdRow.labels[2].title')
                "
                tag="h3"
                weight="bold"
              />
              <Label
                class="text-content-subtitle"
                color="tertiary"
                align="left"
                size="x-small"
                bold="thin"
                >{{
                  $t("pages.howitworks.sections.thirdRow.labels[2].description")
                }}
              </Label>
            </div>
          </div>
        </Section>
      </div>
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import { Section, Label, Link, Button } from "../../atoms";
import { mapGetters } from "vuex";
import { Heading } from "@sc/ds-ui-component-library";
import { PAGES } from "@/enums/pages";

export default {
  components: {
    Button,
    Label,
    Link,
    Page,
    Section,
    Heading
  },
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: this.$t("meta.howItWorks.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.howItWorks.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.howItWorks.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.howItWorks.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.howItWorks.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.howItWorks.image")
        }
      ]
    };
  },
  methods: {
    onClickSearch() {
      this.$router.push({
        name: PAGES.SEARCH
      });
    },
    openRetakePage() {
      this.$router.push({
        name: PAGES.RETAKE
      });
    }
  },
  computed: {
    ...mapGetters(["getToken", "getLoadingAuthentication"])
  }
};
</script>

<style lang="css" scoped src="./HowItWorksPage.css"></style>
