import { render, staticRenderFns } from "./HowItWorksPage.vue?vue&type=template&id=7797f6f9&scoped=true"
import script from "./HowItWorksPage.vue?vue&type=script&lang=js"
export * from "./HowItWorksPage.vue?vue&type=script&lang=js"
import style0 from "./HowItWorksPage.css?vue&type=style&index=0&id=7797f6f9&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7797f6f9",
  null
  
)

export default component.exports